.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid var(--blackOpacity10);
  position: relative;
}

.headerWrapper .hRight {
  display: flex;
  align-items: center;
  gap: 15px;
}

.headerWrapper .mobileMenu {
  display: none;
  background-color: var(--dark);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.headerWrapper .mobileMenu span:not(:last-child) {
  margin-bottom: 6px;
}

.headerWrapper .mobileMenu span {
  width: 25px;
  height: 3px;
  display: block;
  background: var(--white);
  border-radius: 2px;
  transition: all 0.2s ease-in-out;
}

.mobileMenu.mobileMenuActive span:nth-child(2) {
  opacity: 0;
}

.mobileMenu.mobileMenuActive span:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(10px) rotate(45deg);
}

.mobileMenu.mobileMenuActive span:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.headerTabs {
  display: flex;
  gap: 30px;
}

.headerTab {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 75px;
  background-color: var(--light);
  border-radius: 10px;
  cursor: pointer;
}

.headerTab:hover {
  background-color: var(--blackOpacity10);
}

.headerTab p {
  margin-left: 15px;
  color: var(--dark);
  font-weight: 600;
}

.headerTab.active {
  background-color: var(--dark);
  color: var(--white);
}

.headerTab.active p {
  color: var(--white);
}

.headerTab.active svg path {
  fill: var(--white);
}

.userProfile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--blackOpacity10);
}

.userProfile img,
.userProfile .ant-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profileComponent {
  position: absolute;
  top: 110px;
  right: 85px;
  max-width: 409px;
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 3;
}

.profileheader {
  max-width: 409px;
  width: 100%;
  height: 100px;
  background-color: var(--dark);
  border-radius: 10px 10px 0 0;
}

.profileBottom {
  padding: 0 30px;
}

.profileComponent .userProfile {
  width: 100px;
  height: 100px;
}

.profileBodyContent {
  margin-top: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--blackOpacity10);
  padding-bottom: 30px;
}

.profileBodyContent .mainHeading {
  margin-top: 10px;
}

.profileFooter {
  border-top: 1px solid var(--blackOpacity10);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.profileFooter p {
  color: var(--dark);
  margin-left: 10px;
}

.profileFooter .footerBtn {
  width: 100%;
  text-align: center;
}

/* -----------------Responsive CSS------------ */

@media (max-width: 1200px) {
  .headerWrapper .bLogo svg {
    width: 225px;
  }

  .headerTabs {
    gap: 15px;
  }

  .headerTabs .headerTab {
    width: 150px;
  }
}

@media (max-width: 1024px) {
  .headerWrapper {
    padding: 20px;
  }

  .headerWrapper .mobileMenu {
    display: block;
  }

  .headerTabs {
    display: flex;
    gap: 30px;
  }

  .headerTabs.hCenter {
    background-color: var(--dark);
    position: fixed;
    top: 90px;
    flex-direction: column;
    right: 0;
    padding: 50px 0;
    height: 100vh;
    max-width: 300px;
    z-index: 9;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    transform: translateX(300px);
    transform-origin: 0 100%;
    transition: all 0.3s ease-in-out;
  }

  .headerTab {
    flex-direction: column;
    height: unset;
    padding: 15px 0;
    background-color: var(--blackOpacity10);
  }

  .headerTab:hover p {
    color: var(--white);
  }

  .headerTab:hover svg path {
    fill: var(--white);
  }

  .headerTab p {
    margin: 10px 0 0 0;
    color: var(--grey);
    font-size: 16px;
  }

  .headerTab svg path {
    fill: var(--grey);
  }

  .headerTab.active {
    background-color: var(--white);
  }

  .headerTab.active svg path {
    fill: var(--dark);
  }

  .headerTab.active p {
    color: var(--dark);
  }

  .headerTabs.hCenter.menuOpen {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .profileComponent {
    top: 90px;
    right: 60px;
  }

  .profileComponent .profileheader {
    height: 70px;
  }

  .profileComponent .userProfile {
    width: 75px;
    height: 75px;
  }

  .profileComponent .profileBodyContent {
    padding-bottom: 20px;
  }

  .profileComponent .profileFooter {
    padding: 20px 0;
  }

  .profileComponent .profileBottom {
    padding: 0 20px;
  }
}

@media (max-width: 500px) {
  .headerWrapper {
    height: auto;
  }

  .headerWrapper .bLogo svg {
    width: 175px;
  }

  .userProfile {
    width: 40px;
    height: 40px;
  }

  .profileComponent {
    right: 0;
    max-width: 90%;
  }

  .profileComponent .profileheader {
    max-width: 100%;
  }

  .headerTabs.hCenter {
    top: 81px;
  }
}
