@font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    src: url('inter/inter-v13-latin-100.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    src: url('inter/inter-v13-latin-200.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: url('inter/inter-v13-latin-300.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: url('inter/inter-v13-latin-regular.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    src: url('inter/inter-v13-latin-500.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    src: url('inter/inter-v13-latin-600.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: url('inter/inter-v13-latin-700.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    src: url('inter/inter-v13-latin-800.woff2') format('woff2');
  }
  @font-face {
    font-display: swap;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    src: url('inter/inter-v13-latin-900.woff2') format('woff2');
  }