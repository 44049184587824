.login-wrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 35px;
  border-radius: 10px;
  height: auto;
  background: antiquewhite;
}
.login-wrapper h2 {
  text-align: center;
  margin: 1rem auto;
}
.signup-wrapper {
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 35px;
  border-radius: 10px;
  height: auto;
  background: antiquewhite;
}
.signup-wrapper h2 {
  text-align: center;
  margin: 1rem auto;
}
.loginFormWrapperLogo {
  margin-bottom: 30px;
}
.loginFormWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.loginForm {
  max-width: 450px;
  width: 100%;
  padding: 60px;
}
.loginForm .commonButton {
  height: auto;
  width: 100%;
  padding: 10px 10px;
  margin-top: 30px;
}
.loginForm .commonButton:hover {
  color: var(--dark) !important;
  background-color: transparent !important;
  border: 2px solid var(--dark);
}
.loginOption {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: var(--grey);
}
.loginOption span {
  text-decoration: underline;
}
.loginForm .ant-col-16 {
  margin: 0;
  max-width: 100% !important;
}
.loginForm .ant-input-affix-wrapper,
.loginForm .ant-input {
  padding: 10px;
}
.loginForm .ant-form-item {
  margin-bottom: 0;
}
.loginForm .ant-form-item input {
  margin-bottom: 15px;
}
.loginForm .ant-form-item .ant-input-affix-wrapper input {
  margin-bottom: 0px;
}
.loginArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
/* .loginArea::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--blackOpacity10);
  height: 175px;
  width: 1px;
} */
.loginArea .lSide {
  max-width: 320px;
  width: 100%;
  text-align: center;
}
.loginArea .lSide .subInnerHeadingPara {
  margin-bottom: 30px;
}
.loginArea .loginOption span {
  color: var(--dark);
  font-weight: 600;
}
.logWqr {
  position: relative;
}
.logWqr button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
}
.lRight.lSide .lRightqr p span {
  color: var(--dark);
  font-weight: 600;
}
.lRight.lSide .lRightqr .subInnerHeadingPara {
  margin-bottom: 15px;
}
.lRight.lSide .lRightqr .lRightqrDetails {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}
.lRight.lSide .lRightqr .lRightqrDetails svg {
  width: 120px;
}
