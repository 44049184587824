@import url(fonts/font.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}
.ant-layout {
  background: transparent !important;
}
.flexWithBspace {
  display: flex;
  justify-content: space-between;
}
:root {
  --white: #ffffff;
  --light: #f5f5f5;
  --grey: #808080;
  --dark: #333333;
  --black: #000000;
  --blackOpacity10: rgba(0, 0, 0, 0.1);
  --danger: #ff0000;
  --dangerOpacity10: rgba(255, 0, 0, 0.1);
  --primary: #04bbfb;
  --primaryOpacity10: rgba(4, 185, 251, 0.1);
  --success: #4bb543;
  --successOpacity10: rgba(75, 181, 67, 0.1);
  --pending: #f29339;
  --pendingOpacity10: rgb(242, 147, 57, 0.1);
}
body {
  overflow: hidden;
}
a {
  text-decoration: none;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  line-height: normal;
  vertical-align: baseline;
}
/* ------------body-------------- */
.commonBody {
  padding: 30px 30px 0;
  width: 100%;
  float: right;
  height: calc(100vh - 136px);
  display: flex;
  flex-direction: column;
}
.centerBody {
  height: calc(100vh - 136px);
  overflow: auto !important;
}
/* ------------property classes-------------- */
.padd30 {
  padding: 30px;
}
.bgLight {
  background-color: var(--light);
}
.radius10 {
  border-radius: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 40px;
}
.cursorPointer {
  cursor: pointer;
}
.boxShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.m0 {
  margin: 0 !important;
}
.border0 {
  border: none;
}
/* ------------ant scrollbar-------------- */
.commonButton .anticon-loading svg {
  fill: var(--white);
}
.anticon-loading svg {
  fill: var(--dark);
}
/* ------------scrollbar-------------- */
.commonScrollbar::-webkit-scrollbar {
  width: 5px;
}
.commonScrollbar::-webkit-scrollbar-track {
  padding-left: 5px;
}
.commonScrollbar::-webkit-scrollbar-thumb {
  background-color: var(--grey);
  border-radius: 30px;
}
::-webkit-scrollbar {
  display: none;
}
/* ------------notFound-------------- */
.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
}
.wantlogout {
  margin: 1rem 0;
}
.wantlogout span {
  cursor: pointer;
  color: var(--dark);
  font-weight: 600;
  text-decoration: underline;
}
.mt-20 {
  margin-top: 20px;
}
.notFound .commonButton {
  margin-top: 30px;
}
/* ------------Ant, Info Button-------------- */
.ant-btn-default,
.infoBtn {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 7px 32.5px;
  line-height: normal;
  font-size: 14px;
  line-height: normal;
}
.ant-btn,
.infoBtn {
  height: auto;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover,
.infoBtn:hover {
  color: var(--primary);
  background-color: transparent;
  border: 1px solid var(--primary);
}
/* -----------------------info reverse btn---------------- */
.infoRevBtn {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  border: 1px solid transparent;
  box-shadow: none;
  padding: 5px 10px;
  line-height: normal;
  font-size: 12px;
  border: 1px solid var(--dark);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
.infoRevBtn:hover {
  color: var(--dark);
  background-color: var(--white);
  border: 1px solid var(--dark);
}
/* ------------Square Button-------------- */
.squareBtn.ant-btn {
  width: 41px;
  min-width: 41px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: normal;
}
.squareBtn.ant-btn:hover svg path {
  fill: var(--primary);
}
.squareBtn.ant-btn.remove {
  color: var(--danger);
  background-color: transparent;
  border: 1px solid var(--danger);
}
.squareBtn.ant-btn.remove svg path {
  fill: var(--danger);
}
.squareBtn.ant-btn.remove:hover {
  background-color: var(--danger);
  border: 1px solid transparent;
}
.squareBtn.ant-btn.remove:hover svg path {
  fill: var(--white);
}
/* ------------common Button-------------- */
.commonButton {
  padding: 15px 70px;
  border-radius: 5px;
  background-color: var(--dark);
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  line-height: normal;
}
.commonButton:hover {
  color: var(--dark) !important;
  background-color: transparent !important;
  border: 2px solid var(--dark) !important;
}
/* ------------common Small Button-------------- */
.commonButtonSmall {
  padding: 8.5px 39.5px;
  border-radius: 5px;
  background-color: var(--dark);
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  line-height: normal;
}
.commonButtonSmall:hover {
  color: var(--dark) !important;
  background-color: transparent !important;
  border: 2px solid var(--dark) !important;
}
.commonButtonSmallTrans {
  padding: 8.5px 39.5px;
  border-radius: 5px;
  background-color: var(--white);
  color: var(--dark);
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid var(--dark);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  line-height: normal;
}
.commonButtonSmallTrans:hover {
  color: var(--white) !important;
  background-color: var(--dark) !important;
  border: 2px solid var(--dark) !important;
}
/* ------------Success Button-------------- */
.successBtn {
  background-color: var(--success);
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8.5px 39.5px;
  border-radius: 5px;
  color: var(--white);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
}
.successBtn:hover {
  color: var(--success) !important;
  background-color: transparent !important;
  border: 2px solid var(--success) !important;
}
.successBtn:hover path {
  fill: var(--success);
}
.successBtnTrans {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8.5px 39.5px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  border: 2px solid transparent;
  border-color: var(--success);
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: var(--success);
}
.successBtnTrans path {
  fill: var(--success);
}
.successBtnTrans:hover {
  background-color: var(--success) !important;
  color: var(--white) !important;
}
.successBtnTrans:hover path {
  fill: var(--white);
}
/* ------------Common Tab-------------- */
.ant-tabs-top > .ant-tabs-nav {
  margin: 30px 0;
}
.ant-tabs-top > .ant-tabs-nav::before {
  display: none;
}
.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 30px;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab,
button.commonTabBtn {
  padding: 12px 15px;
  border-radius: 5px 5px 0 0;
  color: var(--dark);
  background: var(--white);
  transition: 0.2s all ease-in-out;
  min-width: 150px;
  justify-content: center;
  filter: brightness(0.9);
  border: none;
  cursor: pointer;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab:hover,
button.commonTabBtn:hover {
  background: var(--white);
  filter: brightness(1);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 10px;
}
.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: unset !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
button.commonTabBtn.active {
  color: var(--white) !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active,
button.commonTabBtn.active {
  background: var(--dark);
  filter: brightness(1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-tab-btn,
button.commonTabBtn {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab-active:after {
  display: none !important;
}
.ant-tabs-tab.ant-tabs-tab-active:before {
  display: none !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background: none;
  width: 4px !important;
}
/* ------------Heading-------------- */
.mainHeading {
  color: var(--dark);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.mainHeadingPara {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
}
.subHeading {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.subInnerHeading {
  color: var(--dark);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.subInnerHeadingPara {
  color: var(--grey);
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
}
/* ------------Model Design-------------- */
.ant-modal-content {
  padding: 0px !important;
}
.ant-modal-body::-webkit-scrollbar {
  display: block !important;
}
.ant-modal-body::-webkit-scrollbar {
  width: 5px;
}
.ant-modal-body::-webkit-scrollbar-track {
  background: var(--light);
}
.ant-modal-body::-webkit-scrollbar-thumb {
  background: var(--primary);
}
.ant-modal-content .ant-modal-close {
  top: 20px;
  right: 20px;
  color: var(--dark);
}
.ant-modal-content .ant-modal-close:hover {
  background-color: transparent;
}
.ant-modal-content .ant-modal-header {
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0;
}
.ant-modal-content .ant-modal-header .ant-modal-title {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
}
.ant-modal-content .ant-modal-body {
  padding: 20px;
  max-height: 600px;
  overflow: auto;
}
.ant-modal-content .ant-modal-body .ant-form-item {
  margin-bottom: 10px;
}
.ant-modal-content .ant-modal-body .ant-form-item:last-child {
  margin-bottom: 0;
}
.modelFooterBtns {
  display: flex;
  gap: 15px;
  flex-direction: row;
}
.modelFooterBtns Button:first-child {
  background-color: var(--grey);
}
.modelFooterBtns Button:last-child {
  background-color: var(--danger);
}
.modelFooterBtns Button:first-child:hover {
  border-color: var(--grey) !important;
  color: var(--grey) !important;
}
.modelFooterBtns Button:last-child:hover {
  border-color: var(--danger) !important;
  color: var(--danger) !important;
}
/* ------------Form-------------- */
input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--blackOpacity10);
  border-radius: 4px;
  font-size: 14px;
  margin-top: 5px;
}
input:read-only {
  background-color: var(--light) !important;
  border: none !important;
}
input[type="file"],
input[type="file"]:hover {
  border: none;
  cursor: pointer;
  max-width: 222px;
  width: 100%;
}
.uploadImage .ant-btn {
  margin-top: 5px;
}
.ant-input {
  background-color: transparent;
  line-height: normal;
}
label,
.ant-form-item .ant-form-item-label > label,
.ant-form-vertical .ant-form-item-label {
  color: var(--dark);
  font-size: 15px;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.ant-form-vertical .ant-form-item-label > label::after {
  visibility: visible;
}
.ant-form-item input,
.inputLayout,
input,
.ant-input {
  font-size: 15px;
  color: var(--dark);
  border: 1px solid var(--blackOpacity10);
  padding: 10px;
  border-radius: 5px;
  background-color: transparent;
}
.ant-upload.ant-upload-select {
  margin-top: 5px;
  display: block;
}
::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}
.ant-form-item .ant-input-affix-wrapper input {
  border: none;
}
.ant-form-item .ant-input-affix-wrapper {
  border: 1px solid var(--blackOpacity10);
}
.ant-form-item input:focus,
.ant-form-item input:active,
.ant-form-item input:hover,
.ant-form-item input:focus-within,
.inputLayout:focus,
.inputLayout:active,
.inputLayout:hover,
.inputLayout:focus-within,
input:focus,
input:active,
input:hover,
input:focus-within,
.ant-input:focus,
.ant-input:active,
.ant-input:hover,
.ant-input:focus-within,
.ant-form-item .ant-input-affix-wrapper:focus,
.ant-form-item .ant-input-affix-wrapper:active,
.ant-form-item .ant-input-affix-wrapper:hover,
.ant-form-item .ant-input-affix-wrapper:focus-within {
  border: 1px solid var(--dark);
  outline: none;
  box-shadow: none;
}
.ant-form-item .ant-input-affix-wrapper input:focus,
.ant-form-item .ant-input-affix-wrapper input:active,
.ant-form-item .ant-input-affix-wrapper input:hover,
.ant-form-item .ant-input-affix-wrapper input:focus-within {
  border: none;
  outline: none;
  box-shadow: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}
.errorMessage {
  color: var(--danger);
  font-size: 12px;
  display: block;
  margin-top: 5px;
}
.imageUploadLimit {
  color: var(--grey);
  font-size: 10px;
  display: block;
  margin: 10px 0;
  max-width: 300px;
  font-style: italic;
}
.smallFormFields {
  display: flex;
  gap: 30px;
}
.uploadImage .ant-form-vertical .ant-form-item-row {
  display: flex;
  flex-direction: row !important;
}
/* ------------ant-menu-------------- */
.ant-menu-inline.ant-menu-root {
  background-color: transparent;
  max-width: 250px;
  width: 100%;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 10px;
  padding: 15px;
}
/* ------------radio-------------- */
.ant-switch {
  min-width: 45px;
  height: 25px;
}
.ant-switch.ant-switch-checked {
  background-color: var(--success) !important;
}
.ant-switch .ant-switch-handle {
  top: 5px;
  width: 14px;
  height: 14px;
  inset-inline-start: 5px;
}
/* ------------Payment option radio-------------- */
.ant-radio-wrapper .ant-radio-checked::after {
  border: 1px solid var(--primary);
}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--primary);
}
.ant-radio-wrapper span.ant-radio + * {
  padding: 0;
}
.ant-radio-wrapper {
  margin: 0;
}
/* ------------Drawer-------------- */
.ant-drawer .ant-drawer-body {
  padding: 20px;
}
.ant-drawer .ant-drawer-body .ant-tabs-top > .ant-tabs-nav {
  margin-top: 0;
}
/* ------------table-------------- */
.commonProfilePic,
.commonProfilePic .ant-image {
  width: 45px;
  height: 45px;
}
.commonProfilePic img,
.commonProfilePic .ant-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.commonProfileWrapper {
  display: flex;
  align-items: end;
  gap: 15px;
}
.commonProfileWrapper .ant-form-item {
  margin: 0 0 0 0;
}
.commonProfileWrapper .commonProfilePic .ant-image img {
  border-radius: 50%;
  border: 2px solid var(--light);
}
.ant-table-wrapper .ant-table-content .ant-table-cell {
  padding: 12px 15px;
}
.ant-table-content {
  overflow: auto;
}
.ant-table-content table {
  width: 100%;
  table-layout: auto;
}
.ant-table-wrapper .ant-table-content th.ant-table-cell {
  background-color: var(--white);
  color: var(--dark);
  font-size: 16px;
}
.ant-table-wrapper .ant-table-content th.ant-table-cell:before {
  display: none;
}
.ant-table-wrapper .ant-table-content td.ant-table-cell {
  font-size: 15px;
  color: var(--dark);
  vertical-align: middle;
}
.ant-table-wrapper .ant-table-cell-fix-right {
  background: transparent;
  position: relative !important;
}
.ant-table-wrapper thead.ant-table-thead {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ant-table-wrapper thead.ant-table-thead tr .ant-table-cell {
  padding: 15px 15px;
}
.ant-table-wrapper thead.ant-table-thead tr th:last-child,
.ant-table-wrapper tbody.ant-table-tbody tr td:last-child {
  text-align: center;
}
.ant-table-wrapper .ant-table-row .ant-table-cell:last-child > div {
  margin: 0 auto;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 15px 0 0;
}
.ant-table-wrapper .ant-pagination li {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-table-wrapper .ant-pagination .ant-pagination-item a {
  padding: 0 5px;
}
.ant-table-wrapper .ant-pagination .ant-pagination-item-active a {
  color: var(--dark);
  font-size: 15px;
}
.ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  border-color: var(--dark);
  color: var(--dark);
}
/* ------------Table Icons-------------- */
.actionIcons {
  display: flex;
  gap: 12px;
  justify-content: center;
}
.actionIcons > div {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.actionIcons > div svg {
  height: 18px;
  width: auto;
}
.actionIcons > div svg path {
  fill: var(--grey);
}
.actionIcons > div svg:hover path {
  fill: var(--dark);
}
/* ------------Table Designing-------------- */
.tAmount {
  width: min-content;
  min-width: 50px;
  padding: 3px 8px;
  border-radius: 20px;
  text-align: center;
  color: var(--success);
  font-weight: 600;
  border: 1px solid var(--success);
}
.tDatetime {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  background-color: var(--pendingOpacity10);
  padding: 2px 5px;
  width: 125px;
}
.tChain {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}
.tType {
  text-transform: uppercase;
  font-size: 14px;
}
.tDatetime span {
  margin-right: 8px;
}
.tOrder_ID {
  font-weight: 600;
}
.tStatus {
  width: fit-content;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
  border-radius: 20px;
  white-space: nowrap;
}
.tStatus span {
  padding: 5px 6px;
  margin-right: 8px;
  border-radius: 50%;
}
.tStatus span svg {
  height: auto;
  width: 15px;
}
.tStatus span svg path {
  fill: var(--white);
}
.tStatusCompleted {
  background-color: var(--successOpacity10);
}
.tStatusCompleted span {
  background-color: var(--success);
}
.tStatusPending {
  background-color: var(--pendingOpacity10);
}
.tStatusPending span {
  background-color: var(--pending);
}
.tStatusIS {
  background-color: var(--successOpacity10);
}
.tStatusIS span {
  background-color: var(--success);
}
.tStatusOS {
  background-color: var(--blackOpacity10);
}
.tStatusOS span {
  background-color: var(--grey);
}
.tAddress {
  width: fit-content;
  font-weight: 500;
  font-size: 16px;
}

.tpaidcoin,
.tpaidcoin svg {
  width: 25px;
  height: 25px;
}
.tSku {
  font-weight: 600;
}
.tImage,
.tImage .ant-image {
  width: 50px;
  height: 50px;
}
.tImage img,
.tImage .ant-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--blackOpacity10);
  padding: 5px;
}
/* ------------Address Wrapping-------------- */
.addressWrapper {
  display: flex;
  align-items: center;
}
.addressWrapper p {
  margin: 0;
}
.addressWrapper p span {
  text-overflow: ellipsis;
  overflow: hidden;
}
.generateKeyType,
.posHeaderContent,
.addressWrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 0px);
}
.addressWrapper svg {
  min-width: 16px;
  min-height: 16px;
  margin-left: 10px;
  cursor: pointer;
}
/* ------------Footer-------------- */
.footerWrapper {
  margin-top: auto;
  padding: 30px 0;
}
.footerWrapper p {
  text-align: center;
  color: var(--grey);
}
.footerWrapper .mainHeadingPara {
  position: relative;
  margin-left: 10px;
}
.footerWrapper .mainHeadingPara:after {
  content: "";
  top: 3px;
  left: -8px;
  width: 1px;
  height: 15px;
  position: absolute;
  background-color: var(--grey);
}
.footerWrapper span {
  color: var(--dark);
}
.footerWrapper span a {
  color: var(--dark);
  text-decoration: underline;
}
/* -------------------Responsive CSS----------- */
@media (max-width: 1024px) {
  .commonBody {
    padding: 20px 20px 0;
    width: 100%;
  }
  .commonBody,
  .sideBar,
  .centerBody {
    height: calc(100vh - 91px);
  }
  table * th {
    font-size: 15px !important;
  }
  table * td {
    font-size: 14px !important;
  }
  .padd30 {
    padding: 20px;
  }
  .subHeading {
    margin-bottom: 20px;
  }
  .ant-drawer .ant-drawer-body {
    padding: 20px;
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: 20px 0;
  }
}
@media (max-width: 768px) {
  .mainHeading {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .subHeading {
    font-size: 18px;
  }
  .subInnerHeading {
    font-size: 16px;
  }
  .mainHeadingPara {
    font-size: 15px;
  }
  .subInnerHeadingPara {
    font-size: 14px;
  }
  .commonButton,
  .successBtn {
    font-size: 15px;
    padding: 10px 25px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-tab-btn,
  button.commonTabBtn {
    font-size: 15px;
    font-weight: 500;
  }
  .ant-table-content {
    overflow: auto;
  }
  .ant-table-content table {
    width: 650px;
    table-layout: auto;
  }
  .footerWrapper {
    padding: 20px 0;
  }
  .footerWrapper .mainHeadingPara {
    display: block;
    margin: 0;
  }
  .footerWrapper .mainHeadingPara:after {
    display: none;
  }
}
@media (max-width: 500px) {
  .commonBody,
  .sideBar,
  .centerBody {
    height: calc(100vh - 81px);
  }
}
