.posWrapper .posHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--blackOpacity10);
}
.posWrapper .posContent {
  margin-top: 30px;
}
.posWrapper .posContent .posContentCategoryWrapper {
  display: flex;
  gap: 30px;
  border-radius: 10px;
  background-color: var(--white);
  padding: 30px;
}
.posWrapper
  .posContent
  .posContentCategoryWrapper
  .ant-menu-inline.ant-menu-root {
  height: 450px;
  overflow-y: scroll;
}
.posHeader .posHeaderBtn {
  display: flex;
  gap: 15px;
}
.posHeader .posHeaderBtn svg {
  margin-left: 5px;
}
.posHeader .posHeaderBtn a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: underline;
}
.posHeader .posHeaderBtn a:hover {
  color: var(--primary);
}
.posHeader .posHeaderBtn svg path {
  transition: 0.2s all ease-in-out;
  fill: var(--dark);
}
.posHeader .posHeaderBtn a:hover svg path {
  fill: var(--primary);
}
.posWrapper .posContentHeader {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.posWrapper .posContentHeader .subHeading {
  margin-bottom: 0;
}
.posWrapper .posContentHeader .subButtonWrapper {
  display: flex;
  gap: 15px;
}
.posWrapper .posContentHeader .posContentTabs {
  display: flex;
  gap: 10px;
}
.posContentMain .addressContainer {
  display: flex;
  align-items: baseline;
  gap: 15px;
  flex-wrap: wrap;
}
.posContentMain .addressContainer .paymentDetails {
  background-color: var(--white);
  padding: 15px;
  border-radius: 10px;
  width: fit-content;
}
/* -------------category Details-------------------- */
.categoryDetails {
  width: 100%;
}
.categoryDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.categoryDetailsHeader svg {
  cursor: pointer;
}
.categoryDetailsHeader svg path {
  fill: var(--danger);
  height: 20px;
  width: 50px;
}
.categoryDetailsHeader .categoryDetailsHeaderLeft {
  display: flex;
  align-items: center;
  gap: 10px;
  text-transform: capitalize;
}
.categoryDetailsHeader .categoryDetailsHeaderLeft .subHeading {
  margin-bottom: 0;
}
.categoryDetails .commonButtonSmall {
  margin-right: 15px;
  margin-top: 30px;
}
/* -------------Product Model-------------------- */
.productItemsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.productItemsHeader .subInnerHeading {
  margin-bottom: 0;
}
.productItemsHeader .subHeadingpara .sComplete {
  color: var(--success);
  text-transform: capitalize;
  font-weight: 500;
}
.productItemsHeader .subHeadingpara .sPending {
  color: var(--pending);
}
.productItems {
  display: flex;
  flex-direction: column;
}
.productItems .productItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productItems .productItem:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}
.productItems .productItem .subInnerHeadingPara {
  color: var(--dark);
}
.productItems .productItem .subInnerHeading {
  margin-bottom: 0;
}
.productItems .productItem .subInnerHeadingPara {
  margin-right: auto;
  padding-left: 15px;
}
.productItems .productItem:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--blackOpacity10);
}
.productItems .productItem:not(:first-child) {
  padding-top: 15px;
}
.posOrderInfo .paymentDetails {
  padding: 20px 0 0;
  margin-top: 20px;
  border-top: 1px solid var(--blackOpacity10);
}
.posOrderInfo .paymentDetailsWrapper {
  background-color: var(--light);
  padding: 15px;
  border-radius: 10px;
}
.paymentDetailsWrapper .subHeading {
  margin-bottom: 20px;
}
.paymentDetailsWrapper .subInnerHeadingPara {
  font-weight: 400;
}
.paymentDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.paymentDetailsHeader p {
  margin-bottom: 0;
}
.paymentDetailsHeader svg {
  width: 20px;
  height: 100%;
}
.paymentDetailsBody {
  background-color: var(--light);
  padding: 15px;
  border-radius: 10px;
}
.paymentDetailsBody .subInnerHeading {
  margin-bottom: 0;
}
.paymentDetailsBody .paymentDetailsBodyContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.paymentDetailsBody .paymentDetailsBodyContent svg {
  cursor: pointer;
}
.paymentDetailsBody .paymentDetailsBodyContent:first-child {
  padding-top: 0;
}
.paymentDetailsBody .paymentDetailsBodyContent:last-child {
  padding-bottom: 0;
}
.paymentDetailsBody .paymentDetailsBodyContent a {
  color: var(--primary);
  margin-right: 5px;
}
.paymentDetailsBody .paymentDetailsBodyContent:not(:last-child) {
  border-bottom: 1px solid var(--blackOpacity10);
}
.qrContentWrapper .successFooter {
  margin-top: 30px;
}
.qrContentWrapper .successFooter a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: underline;
  margin-left: 5px;
}
/* -------------POS Category Model-------------------- */
.posCategoryModelInner {
  display: flex;
}
.posCategoryModelInner input[type="text"] {
  margin-top: 0;
  margin-right: 15px;
}
.posCategoryModelBtns {
  display: flex;
  gap: 10px;
}
.posCategoryModelAdded .posCategoryModelInner {
  margin-top: 15px;
}
.posCategoryModelAdded {
  margin-left: 15px;
}
/* -------------Paylinks Delete Model-------------------- */
.modelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modelWrapper .cautionIcon svg {
  width: 50px;
  height: 100%;
  margin: 15px 0 15px;
}
.modelWrapper .mainHeading {
  margin-bottom: 5px;
}
.modelWrapper .modelFooterBtns {
  margin-top: 30px;
}
/* -------------Media Query-------------------- */
@media (max-width: 1200px) {
  .posWrapper .posHeader {
    padding-bottom: 20px;
  }
  .posWrapper .posContent {
    margin-top: 20px;
  }
  .posWrapper .posContent .posContentHeader {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .posWrapper .posContentHeader .subButtonWrapper {
    margin-left: auto;
    gap: 10px;
  }
  .posWrapper .posContentHeader .subButtonWrapper .successBtn,
  .posWrapper .posContentHeader .subButtonWrapper button {
    margin-bottom: 15px;
  }
}
@media (max-width: 1024px) {
  .posWrapper .posContent {
    margin-top: 20px;
  }
  .posWrapper .posContentHeader {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .posWrapper .posContentHeader .posContentTabs {
    width: 100%;
    overflow: auto;
  }
  .posWrapper .posContent .posContentCategoryWrapper {
    flex-direction: column;
  }
  .posWrapper
    .posContent
    .posContentCategoryWrapper
    .ant-menu-inline.ant-menu-root {
    max-width: 100%;
    height: auto;
    background-color: var(--white);
  }
  .posWrapper .posContent .posContentCategoryWrapper {
    gap: 0;
    padding: 0;
    background-color: transparent;
  }
  .categoryDetails {
    border-radius: 10px;
    padding: 20px;
    background-color: var(--white);
    margin-top: 20px;
  }
  .categoryDetailsHeader {
    margin-bottom: 0px;
    padding: 0 0 20px;
  }
  .categoryDetails .commonButtonSmall {
    margin: 15px 10px 0 0;
  }
}
@media (max-width: 500px) {
  .posWrapper .posContentHeader .subButtonWrapper {
    overflow: auto;
    width: 100%;
  }
  .posWrapper .posHeader {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
  .paymentDetailsBody .paymentDetailsBodyContent {
    flex-direction: column;
    align-items: flex-start;
  }
  .categoryDetailsHeader {
    gap: 10px;
    align-items: baseline;
  }
  .categoryDetailsHeader .categoryDetailsHeaderLeft {
    flex-direction: column;
    align-items: flex-start;
  }
  .categoryDetails .ant-form-item-control-input-content button {
    display: block;
    width: 100%;
  }
  .categoryDetails .ant-form-item-control-input-content button:first-child {
    margin-bottom: 10px;
  }
}
